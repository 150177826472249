
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import StandardPage from 'components/pages/StandardPage';
import { i18nConfig, languagesEnabled } from 'language.config';
import { projectId } from 'lib/sanity.api';
import { getAllPagePaths, getHomePage, getPage, getSettings, } from 'lib/sanity.client';
import { PagePreviewData, PageProps, PageQuery } from 'lib/types';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { PreviewSuspense } from 'next-sanity/preview';
import { lazy } from 'react';
const PreviewStandardPage = lazy(() => import('components/pages/PreviewStandardPage'));
const getStaticProps: GetStaticProps<PageProps, PageQuery, PagePreviewData> = async (context) => {
    if (!projectId) {
        return {
            props: {
                preview: false,
                token: null,
                previewLocale: null,
                combinedSlugPath: null,
                page: {},
                settings: {},
                locale: null,
            },
            revalidate: undefined,
        };
    }
    const { params: { index }, preview = false, previewData = {}, locale, } = context;
    const token = previewData.token || null;
    const combinedSlugPath = index?.join('/') || '';
    const getCorrectPage = async () => combinedSlugPath === ''
        ? getHomePage(languagesEnabled ? locale : null)
        : getPage(languagesEnabled ? locale : null, combinedSlugPath);
    const [page, settings] = await Promise.all([getCorrectPage(), getSettings(locale)]);
    const notFound = !page || JSON.stringify(page) === '{}';
    if (notFound && !preview) {
        return {
            notFound: true,
        };
    }
    return {
        props: {
            preview,
            token,
            previewLocale: previewData.locale || null,
            combinedSlugPath,
            page: page || {},
            settings: settings || {},
            locale,
        },
        revalidate: process.env.SANITY_REVALIDATE_SECRET ? undefined : 60,
    };
};
// @ts-ignore
export const getStaticPaths: GetStaticPaths = async () => {
    const allPagePaths = await getAllPagePaths();
    const formatedPaths = allPagePaths.map(({ slug, __i18n_lang }) => ({
        params: { index: slug.split('/') },
        locale: __i18n_lang,
    }));
    const formatedIndexPages = i18nConfig.languages.map((lang) => ({
        params: { index: false },
        locale: lang.id,
    }));
    const paths = [...formatedPaths, ...formatedIndexPages];
    return {
        paths,
        fallback: 'blocking',
    };
};
export default function Page(props: PageProps) {
    const { preview, token, combinedSlugPath, page, settings, locale, previewLocale, } = props;
    if (preview) {
        return (<PreviewSuspense fallback={<StandardPage preview loading page={page} settings={settings} locale={previewLocale}/>}>
        <PreviewStandardPage token={token} slug={combinedSlugPath} locale={previewLocale || i18nConfig.base}/>
      </PreviewSuspense>);
    }
    return <StandardPage page={page} settings={settings} locale={locale}/>;
}

    async function __Next_Translate__getStaticProps__1914f85d8b6__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[[...index]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1914f85d8b6__ as getStaticProps }
  