
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Container from 'components/parts/Container';
import Layout from 'components/sections/Layout';
import ModuleBuilder from 'components/sections/ModuleBuilder';
import { iPage, iSettings } from 'lib/types';
export default function StandardPage(props: {
    preview?: boolean;
    loading?: boolean;
    page: iPage;
    settings: iSettings;
    locale?: string;
}) {
    const { preview, loading, page, settings, locale } = props;
    return (<>
      <Layout preview={preview} loading={loading} page={page} settings={settings}>
        <div>
          <ModuleBuilder page={page}/>
        </div>
      </Layout>
    </>);
}

    async function __Next_Translate__getStaticProps__1914f85d90e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//StandardPage',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1914f85d90e__ as getStaticProps }
  