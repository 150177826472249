import dynamic from 'next/dynamic'
const Textblock = dynamic(() => import('../modules/Textblock'))
const Hero = dynamic(() => import('../modules/Hero'))
const SalesObjectListing = dynamic(() => import('../modules/SalesObjectListing'))
const QuestionsAndAnswers = dynamic(() => import('../modules/QuestionsAndAnswers'))
const PageHeader = dynamic(() => import('../modules/PageHeader'))

export default function ModuleBuilder({ page }) {

  return (
    <>
      {page.modules?.map((module, index) => {
        const { _type, _key } = module

        switch (_type) {
          case 'textblock':
            return <Textblock key={_key | index} {...module} />
          case 'hero':
            return <Hero key={_key | index} {...module} />
          case 'salesObjectListing':
            return <SalesObjectListing key={_key | index} {...module} />
          case 'questionsAndAnswers':
            return <QuestionsAndAnswers key={_key | index} {...module} />
          case 'pageHeader':
            return <PageHeader key={_key | index} {...module} />
          default:
            return null
        }
      })}
    </>
  )
}
